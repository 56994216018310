import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const Sitemap = () => {
  const query = useStaticQuery(PageLinkQuery);
  const links = query.allSitePage.nodes
  
  return (<>
    <head>
      <meta name="robots" content="noindex" />
      <meta name="robots" content="nofollow" />
    </head>
  
    <body>
      <div className="flex flex-col">
        { links.map( (link: { path: string }) => <Link to={link.path}>{link.path}</Link> )}
      </div>
    </body>
  </>)
}

const PageLinkQuery = graphql`
  query {
    allSitePage {
      nodes {
        path
      }
    }
  }
`;

export default Sitemap